jdiv {
  font-family: 'Roboto Flex', sans-serif !important;
}

#jvlabelWrap /* button */ {
  min-width: 160px !important;
  font-size: 14px !important;
}

jdiv[class^='labelStatus'] /* envelope icon */ {
  display: none;
}
