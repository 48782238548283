@import 'react-calendar.css';
@import 'jivo-widget.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Apple Color Emoji';
  src: local('Apple Color Emoji'), url('/next/fonts/AppleColorEmoji.ttf') format('truetype');
  font-display: swap;
}

@layer components {
  .hide-separators[aria-checked='true']::before,
  .hide-separators[aria-checked='true'] + .hide-separators::before,
  .hide-separators[aria-selected='true']::before,
  .hide-separators[aria-selected='true'] + .hide-separators::before {
    display: none;
  }

  .sellmonitor-gpt-shadow {
    --sellmonitor-gpt-shadow-scale: 1.5px;
    --sellmonitor-gpt-color1: #00bef1;
    --sellmonitor-gpt-color1: oklch(74.71% 0.183 225.53);
    --sellmonitor-gpt-color2: #b7fc00;
    --sellmonitor-gpt-color2: oklch(91.18% 0.269 127.06);

    box-shadow: inset 0 0 calc(5 * var(--sellmonitor-gpt-shadow-scale)) #fff,
      inset calc(2 * var(--sellmonitor-gpt-shadow-scale)) 0
        calc(8 * var(--sellmonitor-gpt-shadow-scale)) var(--sellmonitor-gpt-color1),
      inset calc(-2 * var(--sellmonitor-gpt-shadow-scale)) 0
        calc(8 * var(--sellmonitor-gpt-shadow-scale)) var(--sellmonitor-gpt-color2),
      inset calc(2 * var(--sellmonitor-gpt-shadow-scale)) 0
        calc(30 * var(--sellmonitor-gpt-shadow-scale)) var(--sellmonitor-gpt-color1),
      inset calc(-2 * var(--sellmonitor-gpt-shadow-scale)) 0
        calc(30 * var(--sellmonitor-gpt-shadow-scale)) var(--sellmonitor-gpt-color2),
      0 0 calc(5 * var(--sellmonitor-gpt-shadow-scale)) #fff,
      calc(-1 * var(--sellmonitor-gpt-shadow-scale)) 0 calc(8 * var(--sellmonitor-gpt-shadow-scale))
        var(--sellmonitor-gpt-color1),
      calc(1 * var(--sellmonitor-gpt-shadow-scale)) 0 calc(8 * var(--sellmonitor-gpt-shadow-scale))
        var(--sellmonitor-gpt-color2);
  }
}

body {
  display: flex;
  flex-direction: column;
  background: #fff;
  font-family: 'Roboto Flex', 'IBM Plex Sans Arabic', system-ui, -apple-system, 'Segoe UI', Roboto,
    Helvetica, Arial, 'Apple Color Emoji', 'Noto Color Emoji', 'Segoe UI Emoji', sans-serif;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  font-size: 14px;
  font-weight: 410;
  line-height: 1.5;
  overflow-x: hidden;
  position: relative;
  color: #000;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

img,
svg {
  max-width: 100%;
  max-height: 100%;
  vertical-align: inherit;
}

svg {
  display: inline-block;
}

input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.grecaptcha-badge {
  visibility: hidden;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

i,
em,
.italic {
  font-style: oblique 10deg;
}
