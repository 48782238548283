.react-calendar {
  @apply max-w-full overflow-hidden leading-[1.125rem] bg-white backdrop-saturate-200 backdrop-blur-xl rounded-lg ring-1 ring-gray-200 shadow-xl w-80;
}
.react-calendar__viewContainer {
  @apply p-1;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar__navigation {
  @apply flex mx-1 mb-2 h-9;
}
.react-calendar__navigation__arrow {
  @apply text-gray-500;
}
.react-calendar__navigation button {
  @apply flex items-center justify-center w-10 my-1 mx-0.5 bg-transparent rounded-md hover:bg-black/5 focus-visible:ring-2 focus-visible:ring-primary-600 disabled:hover:bg-transparent disabled:hover:cursor-not-allowed disabled:text-gray-200;
}
.react-calendar__navigation__label {
  @apply text-sm font-medium text-gray-700;
}
.react-calendar__month-view__weekdays {
  @apply text-[0.7rem] font-semibold tracking-wider text-center uppercase;
}
.react-calendar__month-view__weekdays__weekday {
  @apply p-2 text-gray-600;
}
.react-calendar__month-view__weekdays__weekday abbr[title] {
  @apply no-underline;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  @apply text-red-600;
}
.react-calendar__month-view__days__day--neighboringMonth {
  @apply text-gray-500;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  @apply py-3 mx-1;
}
.react-calendar__year-view__months {
  @apply !grid grid-cols-3;
}
.react-calendar__month-view__days {
  @apply !grid grid-cols-7;
}
.react-calendar__tile {
  @apply !max-w-full my-0.5 mx-0.5 py-2 rounded-md text-center hover:bg-black/5 focus-visible:ring-2 focus-visible:ring-primary-600 disabled:text-gray-200 disabled:hover:bg-transparent disabled:hover:cursor-not-allowed;
}
.react-calendar__tile.react-calendar__tile--now {
  @apply text-gray-800 bg-gray-100 hover:bg-gray-200;
}
.react-calendar__tile.react-calendar__tile--hasActive,
.react-calendar__tile.react-calendar__tile--active {
  @apply rounded-sm bg-primary-100 text-primary-800 hover:bg-primary-200;
}
.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--rangeStart,
.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--rangeEnd,
.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--rangeStart,
.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--rangeEnd {
  @apply text-white bg-primary-600 hover:bg-primary-500;
}
.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--rangeStart,
.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--rangeStart {
  @apply rounded-l-md;
}
.react-calendar__tile.react-calendar__tile--active.react-calendar__tile--rangeEnd,
.react-calendar__tile.react-calendar__tile--hasActive.react-calendar__tile--rangeEnd {
  @apply rounded-r-md;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  @apply rounded-sm bg-primary-100 text-primary-800;
}
.react-calendar--selectRange
  .react-calendar__tile--active.react-calendar__tile--rangeStart.react-calendar__tile--hover.react-calendar__tile--hoverStart {
  @apply text-white rounded-r-none bg-primary-600 rounded-l-md;
}
.react-calendar--selectRange .react-calendar__tile--hover.react-calendar__tile--hoverEnd {
  @apply text-white bg-primary-600 rounded-r-md;
}

.react-daterange-picker {
  @apply relative flex;
}
.react-daterange-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}
.react-daterange-picker__wrapper {
  @apply flex items-center text-sm bg-white border border-gray-300 rounded-md hover:border-gray-400 grow shrink-0 h-[38px] px-3;
}
.react-daterange-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2);
  flex-grow: 0;
  padding: 0 0 0 2px;
  box-sizing: content-box;
}
.react-daterange-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}
.react-daterange-picker__inputGroup__leadingZero {
  @apply -mr-px;
}
.react-daterange-picker__inputGroup__input[type='number'] {
  @apply !py-2 rounded focus:outline-none focus:border-0 focus:ring-0;
  min-width: 0.54em;
  height: 100%;
  position: relative;
  padding: 0 1px;
  border: 0;
  background: none;
  font: inherit;
  box-sizing: content-box;
  -moz-appearance: textfield;
}
.react-daterange-picker__inputGroup__input::-webkit-outer-spin-button,
.react-daterange-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-daterange-picker__inputGroup__input:invalid {
  @apply !py-0.5 bg-primary-50;
}
.react-daterange-picker__button {
  @apply px-1 py-2 text-gray-500;
}
.react-daterange-picker__button:enabled {
  cursor: pointer;
}
.react-daterange-picker__button:enabled:hover svg,
.react-daterange-picker__button:enabled:focus svg {
  @apply text-gray-700;
}
.react-daterange-picker__button:disabled .react-daterange-picker__button__icon {
  stroke: #6d6d6d;
}
.react-daterange-picker__button svg {
  display: inherit;
}
.react-daterange-picker__calendar {
  @apply absolute !left-0 !top-[calc(100%+8px)] z-[1];
}
.react-daterange-picker--open .react-daterange-picker__wrapper {
  @apply ring-1 ring-primary-500 !border-primary-500;
}
.react-daterange-picker__calendar--closed {
  display: none;
}
